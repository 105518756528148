@import './mixins.sass'
@import './fonts.sass'
@import './assets/css/theme-default'
@import './assets/css/theme-contrast.scss'
@import '../node_modules/bootstrap/scss/bootstrap'
@import '../node_modules/ag-grid-community/styles//ag-grid.css'
@import '../node_modules/ag-grid-community/styles//ag-theme-balham.css'
@import '../node_modules/ag-grid-community/styles//agGridMaterialFont.css'
@import './material-icons-load.css'
@import 'material-icons/iconfont/filled.scss'
@import 'material-icons/iconfont/outlined.scss'

body
  overflow-x: hidden !important
  overflow-y: auto !important
  iframe
    position: absolute
    bottom: 0px

/* Scrollbar Style */

body
  background-color: var(--body-background)
  ::-webkit-scrollbar
    width: 8px
    height: 8px
  ::-webkit-scrollbar-track
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)
  ::-webkit-scrollbar-thumb
    background-color: darkgrey
    border: 1px solid slategrey
    border-radius: 0.4em


/**/

.gs-view-iframe-container
  position: relative
  overflow: hidden
  padding-top: 56.25%

.gs-view-iframe-container-menubar
  position: relative
  overflow: hidden
  padding-top: 1515px

.gs-view-iframe-specific-content
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  border: 0

/* Standard Font provided for UX Team */

p
  font-family: 'Graphik Light', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

a
  font-family: 'Graphik', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: var(--body-link)
  &:hover
    color: var(--body-link-hover)

h1
  font-family: 'Graphik Bold', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-size: 17pt

h2
  font-family: 'Graphik Bold', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-size: 16pt

h3
  font-family: 'Graphik Bold', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-size: 15pt

h4
  font-family: 'Graphik Bold', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

h5
  font-family: 'Graphik Bold', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

label
  font-family: 'Graphik Light', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-weight: 600
  font-size: 14px

input
  font-family: 'Graphik Light', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

button
  font-family: 'Graphik Bold', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

table
  font-family: 'Graphik Light', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

div
  font-family: 'Graphik Light', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

small
  font-family: 'Graphik Light', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-size: 12px

.form-control
  font-family: 'Graphik Light', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-size: 14px
  border-color: var(--input-border)
  background-color: var(--input-background)
  color: var(--body-color)

/**/

/* Accenture Universal Header */

#acn-controls
  z-index: 999 !important

.acn-header-main
    font-family: 'Graphik' sans-serif !important
    height: 48px !important

.acn-header-main *
  box-sizing: border-box !important


.myte-uh
  display: flex !important
  justify-content: space-between !important
  align-items: center !important

.myte-uh-links-container
  display: flex
  align-items: center
  height: 100%
  color: black
  letter-spacing: -1px
  padding: 0 10px 0 15px
  min-width: 180px
  justify-content: center
  transition: all 0.2s ease-in-out
  max-width: 220px
  width: 220px
  &:hover
    cursor: pointer
    opacity: 1 !important
  .myte-options-list
    display: none
    flex-direction: column
    justify-content: center
    position: absolute
    top: 46px
    background-color: white
    color: black
    margin-right: 5px
    max-width: 220px
    width: 220px
    box-shadow: 0px 14px 15px 0 rgba(52,58,64,.2)
    .myte-option
      padding: 10px
      &:hover
       background-color: #ecd9ff

.myte-uh-menu-container
  float: unset !important
  display: flex
  position: unset !important
  top: 0px !important

.acn-chat-main .acn-chat-container
  z-index: 11999 !important

/**/

/* Standard Formats */

.number
    text-align: right

.center
    text-align: center

.red
    color: var(--body-error)

.green
    color: green

.blue
    color: blue

.bold
  font-weight: bold

.underline
  text-decoration: underline

.underline-hover:hover
  text-decoration: underline

.cursor-pointer
  cursor: pointer

/**/

/* Buttons Standar Formats */

button.myte-button
  height: 30px
  min-width: 80px
  outline: none
  border: none
  padding: 0 10px
  letter-spacing: 0
  opacity: 1
  font-size: 14px
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  &:disabled
    background-color: white !important
    background: white !important
    color: #676767 !important
    transition: 0 !important
    border: 1px solid #758695 !important
    &:hover
      cursor: unset !important
      text-decoration: none !important

button.myte-button-sm
  height: 27px
  font-size: 13px
      
button.myte-button.myte-button-submit
  color: #ffffff
  background: #8611D1 0% 0% no-repeat padding-box
  box-shadow: 0px 1px 1px #00000040
  border-color: var(--button-border-color)
  background-position: center
  transition: 0.3s
  &:hover
    background: #8D5ECE radial-gradient(circle, transparent 1%, #8D5ECE 1%) center/15000%
    border: 1px solid black
    text-decoration: underline
  &:active
    background-color: #8D5ECE
    background-size: 100%
    transition: background 0s
    border: 1px solid black
  &:focus
    border: 2px solid #000000
/**/

button.myte-button.myte-button-secondary
  color: var(--secondary-button-font-color)
  background-color: #ffffff
  border:1px solid #7500c0
  transition: 0.3s
  &:hover
      background: var(--secondary-button-hover-color) radial-gradient(circle, transparent 1%, var(--secondary-button-hover-color) 1%) center/15000% 
      text-decoration: none
  &:active
      background-color: var(--secondary-button-background-color) 
      background-size: 100%
      transition: background 0s
      text-decoration: underline
  &:focus
      border: 2px solid #000000
/**/

button.myte-button.myte-button-cancel
  color: #7500C0
  background-color: #ffffff
  border:1px solid #7500c0
  transition: 0.3s
  &:hover
    background: #eed4fd radial-gradient(circle, transparent 1%, #eed4fd 1%) center/15000%
    text-decoration: none
  &:active
    background-color: #eed4fd
    background-size: 100%
    transition: background 0s
    text-decoration: underline
  &:focus
    border: 2px solid #000000

/**/

/** Accessibility **/

/* Buttons */
.accessibility-label
  color: var(--controls-color)
  font-size: 13px
  background: none
  text-decoration: none
  border: none
  font-family: 'Graphik Light', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-weight: 600
  font-size: 14px
  text-align: center
  white-space: nowrap
  margin: 0
    right: 10px
    left: 5px

.screenReaderReadable
  position: absolute !important
  left: -999rem

.accessibility-div
  color: var(--body-color)
  background: none
  text-decoration: none
  border: none
  font-family: 'Graphik Light', sans-serif


/* Skip Navigation Link */
.skip-link
  z-index: 999 !important
  background-color: #7500C0
  width: 200px
  height: 50px
  margin: 5px
  padding: 15px
  top: 60px
  color: #ffffff
  font-weight: bold
  text-align: center
  position: absolute
  box-shadow: 0px 14px 15px 0 rgba(52,58,64,.2)
  transition: transform 0.3s
  text-decoration: none
  cursor: pointer
  transform: translateY(-250%)
  &:hover
    background: #be8fff radial-gradient(circle, transparent 1%, #be8fff 1%) center/15000%
    text-decoration: underline
    color: #ffffff
  &:focus
    transform: translateY(0%)

/* Row Colors */ 
.readonly-row
  background-color: #F3F5F6 !important
  
/* Cell Colors */

.disabled-clicks
  pointer-events: none
  opacity: 0.5


/* Cell Styles

.ag-theme-balham
  .ag-ltr
    .ag-cell.replicate-cell
      padding: 0px
      border-right: 1px solid var(--time-tab-color-border-cell)
      border-top: none
      border-bottom: none

.ag-last-grid
  margin-bottom: 60px


/* Popups Styles: Add Margin Top and Z Index becouse the navbar */

ngb-typeahead-window.dropdown-menu
  z-index: 100
  max-height: 500px 
  overflow-y: auto

.modal-dialog
  margin-top: 120px

.modal
  z-index: 9999

/**/

/* Utils: */

.hidden
  visibility: hidden

// Disable Highlight when selecting text
.no-select
  user-select: none
  -webkit-user-select: none
  -ms-user-select: none
  -webkit-touch-callout: none
  -o-user-select: none
  -moz-user-select: none

  // Emulates <a> tag styles

.link-style
  cursor: pointer
  color: #007bff !important
  text-decoration: none !important
  background-color: transparent
  &:hover
    color: #0056b3 !important
    text-decoration: underline !important

/**/

/* Tooltip Styles: width
.tooltip
  z-index: 12000

.tooltip-width .tooltip-inner
  max-width: 400px
  font-size: 12px
  z-index: 12000


.tooltip-people-picker
  .tooltip-inner
    background-color: #FFF
    color: black
    border: 1px solid #aaa
    .description
      display: flex
      align-items: center
      .profile-picture
        margin-right: 15px
        width: 40px
        height: 40px
        img
          border-radius: 50%
          width: 40px
          height: 40px
  .arrow::before
    border-top-color: #aaa

/* Extend bootstrap layouts for xxl breakpoint */
+screen-size-xxl
  .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8,
  .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
  .col-xxl-auto
    position: relative
    width: 100%
    padding-right: 15px
    padding-left: 15px
  .col-xxl
    -ms-flex-preferred-size: 0
    flex-basis: 0
    -ms-flex-positive: 1
    flex-grow: 1
    max-width: 100%
  .col-xxl-auto
    -ms-flex: 0 0 auto
    flex: 0 0 auto
    width: auto
    max-width: 100%
  .col-xxl-1
    -ms-flex: 0 0 8.333333%
    flex: 0 0 8.333333%
    max-width: 8.333333%
  .col-xxl-2
    -ms-flex: 0 0 16.666667%
    flex: 0 0 16.666667%
    max-width: 16.666667%
  .col-xxl-3
    -ms-flex: 0 0 25%
    flex: 0 0 25%
    max-width: 25%
  .col-xxl-4
    -ms-flex: 0 0 33.333333%
    flex: 0 0 33.333333%
    max-width: 33.333333%
  .col-xxl-5
    -ms-flex: 0 0 41.666667%
    flex: 0 0 41.666667%
    max-width: 41.666667%
  .col-xxl-6
    -ms-flex: 0 0 50%
    flex: 0 0 50%
    max-width: 50%
  .col-xxl-7
    -ms-flex: 0 0 58.333333%
    flex: 0 0 58.333333%
    max-width: 58.333333%
  .col-xxl-8
    -ms-flex: 0 0 66.666667%
    flex: 0 0 66.666667%
    max-width: 66.666667%
  .col-xxl-9
    -ms-flex: 0 0 75%
    flex: 0 0 75%
    max-width: 75%
  .col-xxl-10
    -ms-flex: 0 0 83.333333%
    flex: 0 0 83.333333%
    max-width: 83.333333%
  .col-xxl-11
    -ms-flex: 0 0 91.666667%
    flex: 0 0 91.666667%
    max-width: 91.666667%
  .col-xxl-12
    -ms-flex: 0 0 100%
    flex: 0 0 100%
    max-width: 100%
  .order-xxl-first
    -ms-flex-order: -1
    order: -1
  .order-xxl-last
    -ms-flex-order: 13
    order: 13
  .order-xxl-0
    -ms-flex-order: 0
    order: 0
  .order-xxl-1
    -ms-flex-order: 1
    order: 1
  .order-xxl-2
    -ms-flex-order: 2
    order: 2
  .order-xxl-3
    -ms-flex-order: 3
    order: 3
  .order-xxl-4
    -ms-flex-order: 4
    order: 4
  .order-xxl-5
    -ms-flex-order: 5
    order: 5
  .order-xxl-6
    -ms-flex-order: 6
    order: 6
  .order-xxl-7
    -ms-flex-order: 7
    order: 7
  .order-xxl-8
    -ms-flex-order: 8
    order: 8
  .order-xxl-9
    -ms-flex-order: 9
    order: 9
  .order-xxl-10
    -ms-flex-order: 10
    order: 10
  .order-xxl-11
    -ms-flex-order: 11
    order: 11
  .order-xxl-12
    -ms-flex-order: 12
    order: 12
  .offset-xxl-0
    margin-left: 0
  .offset-xxl-1
    margin-left: 8.333333%
  .offset-xxl-2
    margin-left: 16.666667%
  .offset-xxl-3
    margin-left: 25%
  .offset-xxl-4
    margin-left: 33.333333%
  .offset-xxl-5
    margin-left: 41.666667%
  .offset-xxl-6
    margin-left: 50%
  .offset-xxl-7
    margin-left: 58.333333%
  .offset-xxl-8
    margin-left: 66.666667%
  .offset-xxl-9
    margin-left: 75%
  .offset-xxl-10
    margin-left: 83.333333%
  .offset-xxl-11
    margin-left: 91.666667%
/* ---------------------------- */
 
.ngb-dp-content .ngb-dp-month ngb-datepicker-month div .ngb-dp-weekday  
  color: #212529  

.ag-theme-balham
  .ag-row
    width: inherit
  .ag-filter input[class^=ag-]:not([type]):focus,
  .ag-filter input[type="date"]:focus,
  .ag-filter input[type="text"]:focus,
  .ag-filter input[type="number"]:focus
    box-shadow: 0 0 0 0.1rem #8611D1
    border: 1px solid #8611D1
  .ag-filter-select .ag-picker-field-wrapper:focus
    box-shadow: 0 0 0 0.1rem #8611D1
    border: 1px solid #8611D1
  .ag-menu .myte-custom-select:focus
    box-shadow: 0 0 0 0.1rem #000000
    border: none 
  .ag-menu .filterOptions:focus
    // box-shadow: 0 0 0 0.1rem #8611D1
    color: white !important
  .ag-menu .filterOptions:hover
    // box-shadow: 0 0 0 0.1rem #8611D1
    color: white !important
  .ag-menu .filterOptions.selected
    // box-shadow: 0 0 0 0.1rem #8611D1
    color: white !important
  .ag-menu
    overflow-y: visible
    max-height: none
  .ag-filter-condition .and
    &:focus
      outline: 1px inset black

  .ag-filter-condition .or
    &:focus
      outline: 1px inset black

.alert
  font-size: 15px

.dropdown-item.active, .dropdown-item:active
  color: #4e0e72
  background-color: #e1dcf7

.btn-close:focus
  box-shadow: 0 0 0 0.25rem rgb(114 128 138 / 25%)

.punch-clock-column
  background: #E8EBED

.punch-clock-column-border
  border-right: 1px solid gainsboro !important

.work-shift-column
  background: #E8EBED

.work-shift-column-border
  border-right: 1px solid gainsboro !important

.field-center
  padding-top:4px

ngb-datepicker
  z-index: 12000 !important

/* MatTooltip styles to looks like Bootstrap */

.cdk-overlay-container
  z-index: 13000 !important
  
.mat-mdc-tooltip
  --mdc-plain-tooltip-container-color: rgba(0, 0, 0, 0.92) !important
  --mdc-plain-tooltip-supporting-text-color: #fff !important
  --mdc-plain-tooltip-supporting-text-font: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important
  --mdc-plain-tooltip-supporting-text-size: 0.875rem !important
  --mdc-plain-tooltip-supporting-text-weight: 300 !important
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em

.mdc-tooltip__surface
  text-align: center !important
  padding: 0.25rem 0.5rem !important
  color: white !important
  border-radius: 0.375rem !important
  line-height: 1.5 !important

.matTooltip-width .mdc-tooltip__surface
  max-width: 400px
  font-size: 12px
  z-index: 12000


/* MatTooltip arrow depending on matTooltipPosition */

.mat-mdc-tooltip-panel-below .mdc-tooltip::after
  content     : ''
  display     : block
  position    : absolute
  bottom      : 100%
  border-style: solid
  border-color: transparent transparent rgba(0, 0, 0, 0.92) transparent
  border-width: 5px
  left        : calc(50% - 5px) 

.mat-mdc-tooltip-panel-above .mdc-tooltip::after 
  content: ''
  display: block
  position: absolute
  top: 100%
  border-style: solid
  border-color: rgba(0, 0, 0, 0.92) transparent transparent transparent
  border-width: 5px
  left: calc(50% - 5px)

.mat-mdc-tooltip-panel-left .mdc-tooltip::after 
  content: ''
  display: block
  position: absolute
  left: 100%
  border-style: solid
  border-color: transparent transparent transparent rgba(0, 0, 0, 0.92)
  border-width: 5px
  top: calc(50% - 5px)

.mat-mdc-tooltip-panel-right .mdc-tooltip::after 
  content: ''
  display: block
  position: absolute
  right: 100%
  border-style: solid
  border-color: transparent rgba(0, 0, 0, 0.92) transparent transparent
  border-width: 5px
  top: calc(50% - 5px)

/* ---------------------------- */



  
